<template>
<div>
  <custom-breadcrumb
        :pageTitle="$t('resources.tutorials')"
        :breadcrumb="[
          {
            to: 'resources',
            text: this.$t('Educational Resources')
          },
        ]"
      ></custom-breadcrumb>

  <b-row>
    <b-col cols="12">
      <b-row class="match-height">
        <b-col
          v-for="video in videos"
          :key="video.id"
          sm="6"
          md="4"
          xl="3"
        >
          <b-card
            :img-alt="video.title"
            img-top
            no-body
          >
            <iframe :src="'https://www.youtube.com/embed/'+video.url" 
              :title="video.title" frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              style="width:100%; height:auto;" 
              allowfullscreen></iframe>
            <b-card-body>
              <b-card-title>{{video.title}}</b-card-title>
              <!-- <b-card-text>
                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
              </b-card-text> -->
            </b-card-body>
            <b-card-footer>
              <small class="text-muted">{{$t('resources.last_updated')}}: {{ video.updated_at || video.created_at }}.</small>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>


    </b-col>
  </b-row>
</div>
</template>

<script>
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

import {
  BRow, BCol, BCardGroup, BCard, BCardText, BCardTitle, BCardFooter, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BCardFooter,
    BCardBody,
    CustomBreadcrumb
  },
  data(){
    return {
      videos: []
    }
  },
  mounted(){
    this.getData()
  },
  computed:{
    lang(){
      return this.$store.getters['user/getLang']
    }
  },
  watch:{
    lang(newLang, oldLang){
      this.getData()
    }
  },
  methods:{
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async getData(){
      await this.$http.post('resources/tutorials', {}).then( response => {
        if(response.data.status == 200){
          this.videos = response.data.videos
          console.log(response.data)
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
        }
      })
    }
  }
}
</script>
